import React, { useEffect } from 'react';
import Header from './header';
import '../css/Progressbar.css';
import '../css/welcome.css';
import TransitionAnimation from './Animation/TransitionAnimation';


const NoServiceState = () => {
  const progressValue = 10;

  return (
    <>
      <Header progressValue={progressValue} />
      <TransitionAnimation>
        <div
          style={{ height: "69vh" }}
          className="container page-main1"
          >
          <div className="col-lg-4 col-md-6 col-sm-8 col-9 mx-auto text-center mx-auto">
            <h3>Let’s Get You Started!</h3>
            <p className="mt-4">
            Not all of our dietitians are listed online, including those in your state. Text us now at{" "}
            <a
              href="sms:+16172216494"
              target="_blank"
              rel="noopener noreferrer">
              (617) 221-6494
            </a>
            {" "}
            to schedule your first session.
            </p>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default NoServiceState;
