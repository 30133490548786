import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useCookies } from "react-cookie";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { defaultStyles } from "../utils/helpers/SelectCustoms";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { getInsurenceProviderList, getInsurenceProviderListWithType } from "../redux/action/insurenceProviderAction";
import { INSURENCE_PROVIDER_LIST, INSURENCE_PROVIDER_LIST_WITH_TYPE } from "../utils/constants/insurence-provider";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { Alert, FormControlLabel, Switch, Typography } from "@mui/material";

const InsuranceSelection = () => {
  const [cookies] = useCookies(["evuid"]);
  const progressValue = 20;
  const navigate = useNavigate();
  const [error, setError] = useState({ message: false, type: '' });
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const insurenceProviderList = useSelector((state) =>
    state.insurence[INSURENCE_PROVIDER_LIST]?.map((ins) => {
      return {
        id: ins.id,
        value: ins.payer_id,
        label: ins.payer_name,
      };
    })
  );
  const insurenceProviderListWithType = useSelector((state) =>
    state.insurence[INSURENCE_PROVIDER_LIST_WITH_TYPE]?.map((ins) => {
      return {
        id: ins.id,
        value: ins.payer_id,
        label: ins.payer_name,
      };
    })
  );
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const [selectedInsurance, setInsurance] = useState({});
  const [hasSecondary, setHasSecondary] = useState(false);
  const [selectedSecondary, setSelectedSecondary] = useState(null);
  const [selectedState, setSelectStates] = useState({});
  const [isAcceptedCondition, setIsAcceptedCondition] = useState(false);
  const [secondaryMemberID, setSecondaryMemberID] = useState("");
  const setUserDataHandler = (uData) => {
    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        id: uData.insurence.id,
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
    } else {
      setInsurance({});
    }

    if (uData?.insurence?.medicare_option) {
      setHasSecondary(uData?.insurence.medicare_option);
      setSelectedSecondary({
        id: uData.insurence.medicare_id || '',
        value: uData.insurence.medicare_insurance_provider_id,
        label: uData.insurence.Medicare_insurance_provider_name,
      });
    }

    if (uData?.insurence?.secondary_member_id) {
      setSecondaryMemberID(uData.secondary_member_id);
    }

    if (uData?.insurence?.check_insurance_consent) {
      setIsAcceptedCondition(!!uData?.insurence.check_insurance_consent);
    }

    if (uData.state) {
      setSelectStates(uData.state);
    } else {
      setSelectStates({});
    }
  };

  const handleSecondaryChange = (event) => {
    setError("");
    setHasSecondary(event.target.checked);
  };

  const handleSecondaryProviderChange = (event) => {
    setSelectedSecondary(event);
    setError({ message: false, type: '' });
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            if (
              !insurenceProviderList ||
              (Array.isArray(insurenceProviderList) &&
                insurenceProviderList.length === 0)
            ) {
              dispatch(getInsurenceProviderList());
            }
            if (!insurenceProviderListWithType || (Array.isArray(insurenceProviderListWithType) && insurenceProviderListWithType.length === 0)) {
              dispatch(getInsurenceProviderListWithType());
            }
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/insuranceselection",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleSelectChange = (selectedOption) => {
    if (
      selectedOption &&
      selectedOption.label &&
      (selectedOption.label.includes("HMO") ||
        selectedOption.label.includes("Cigna Local Plus"))
    ) {
      if (selectedState && selectedState.value !== "MA") {
        setErrorMessage(
          "Everlong's services are currently not covered by HMO plans in your state. You can proceed with Private Pay or HSA / FSA."
        );
      }
    } else if (selectedOption.value === "97213") {
      setErrorMessage(
        "Everlong currently does not accept Medicaid. You can proceed with Private Pay or HSA / FSA."
      );
    } else {
      setErrorMessage("");
    }
    setInsurance(selectedOption);
    setIsAcceptedCondition(false);
    setError({ message: false, type: '' });
    setHasSecondary(false);
  };

  const handleSelectButton = () => {
    if (selectedInsurance.value) {
      if (selectedInsurance.value === "04271" && !isAcceptedCondition) {
        setError({ message: "Please acknowledge the referral requirement.", type: "center" });
        return;
      }
      if (hasSecondary && (!selectedSecondary || !selectedSecondary.value)) {
        setError({ message: "Please select secondary or supplemental plan.", type: "left" });
        return;
      }
      if (
        selectedInsurance &&
        selectedInsurance.label &&
        (selectedInsurance.label.includes("HMO") ||
          selectedInsurance.label.includes("Cigna Local Plus"))
      ) {
        if (selectedState && selectedState.value !== "MA") {
          setErrorMessage(
            "Everlong's services are currently not covered by HMO plans in your state. You can proceed with Private Pay or HSA / FSA."
          );
          return;
        }
      }
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          insurence: {
            id: selectedInsurance.id,
            insurance_provider_id: selectedInsurance?.value,
            insurance_provider_name: selectedInsurance?.label,
            check_insurance_consent: isAcceptedCondition,
            medicare_option: selectedInsurance.value === "97214" ? (hasSecondary ? hasSecondary : null) : null,
            medicare_id: (selectedInsurance.value === "97214" && hasSecondary === true) ? selectedSecondary.id : null,
            medicare_insurance_provider_id: (selectedInsurance.value === "97214" && hasSecondary === true) ? selectedSecondary.value : null,
            Medicare_insurance_provider_name: (selectedInsurance.value === "97214" && hasSecondary === true) ? selectedSecondary.label : null,
          },
          secondary_member_id: (selectedInsurance.value === "97214" && hasSecondary === true) ? secondaryMemberID : null,
        })
      );
      setErrorMessage("");
      setError({ message: false, type: '' });
      navigate("/dateofbirth");
    } else {
      setError({ message: "Please select your insurance provider.", type: 'left' });
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAcceptedCondition(e.target.checked);
    setError({ message: false, type: '' });
  };



  return (
    <>
      <Header progressValue={progressValue} backRoute={"/selectstates"} />
      <TransitionAnimation>
        <div className={`container page-main1 InsuranceInformation ${selectedInsurance.value === "97214" ? "" : "mt-0"
          }`}>
          <div className="row">
            <div className="col-lg-12 col-md-8 col-sm-12 text-left mx-auto mt-5">
              <h3 className="mt-4 text-center">
                Select your health insurance provider.
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-10 col-sm-8 col-9 text-left mx-auto">
              <p className="text-center">
                95% of our patients are 100% covered with $0 copay.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-7 col-sm-9 mt-3 col-10 mx-auto insuranceSelect">
            <Select
              options={insurenceProviderList}
              value={selectedInsurance}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              placeholder=""
              onChange={handleSelectChange}
              isSearchable
              noOptionsMessage={() => "No choices"}
              styles={defaultStyles}
              className={``}
            />

            {selectedInsurance && (
              <>
                {selectedInsurance.value === "04271" && (
                  <div className="mt-3 main-check-select" style={{ marginLeft: '-7%' }}>
                    <div>
                      <input
                        type="checkbox"
                        className={`check-hearby ${error.message ? "error" : ""}`}
                        checked={isAcceptedCondition}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <Alert
                      severity="info"
                      sx={{
                        backgroundColor: "#E6D9F5",
                        color: "#8548D3",
                        '& .MuiAlert-icon': {
                          color: "#8548D3",
                          marginRight: "10px",
                        },
                      }}
                    >
                      Your insurance company requires a referral to see a registered
                      dietitian. You acknowledge that you will upload a referral to
                      your patient portal or reschedule your appointment at least 24
                      hours in advance and that failure to do so will result in a
                      no-show/late-cancellation fee. If needed, download our{" "}
                      <a
                        className="link-with-a"
                        href="/src/PatientReferralForm.pdf"
                        download
                        style={{ color: '#8548D3' }}
                      >
                        Patient Referral Form
                      </a>
                      .
                    </Alert>
                  </div>
                )}

                {(selectedInsurance.label === "Private Pay" || selectedInsurance.label === "HSA / FSA") && (
                  <Alert
                    severity="info"
                    sx={{
                      backgroundColor: "#E6D9F5",
                      color: "#8548D3",
                      marginTop: "10px",
                      '& .MuiAlert-icon': {
                        color: "#8548D3",
                      },
                    }}
                  >
                    Cost per session for Private Pay or HSA / FSA is $150.
                  </Alert>
                )}

                {(selectedInsurance.value === "60054-Medicare" ||
                  selectedInsurance.value === "MABLS-Medicare" ||
                  selectedInsurance.value === "63208-Medicare" ||
                  selectedInsurance.value === "04271-Medicare" ||
                  selectedInsurance.value === "87726-Medicare") && (
                    <Alert
                      severity="info"
                      sx={{
                        backgroundColor: "#E6D9F5",
                        color: "#8548D3",
                        marginTop: "10px",
                        '& .MuiAlert-icon': {
                          color: "#8548D3",
                          marginRight: "10px",
                        },
                      }}
                    >
                      You’ve selected a Medicare Advantage plan. If this is incorrect, please choose <i>Medicare</i> instead.<br></br><br></br>

                      Medicare, including Medicare Advantage plans, cover medical nutrition therapy only for diabetes, kidney disease, or recent kidney transplants (within 36 months). A referral is required and must be uploaded in the final booking step.
                    </Alert>
                  )}

                {selectedInsurance.value === "97214" && (
                  <>
                    <Alert
                      severity="info"
                      sx={{
                        backgroundColor: "#E6D9F5",
                        color: "#8548D3",
                        marginTop: "10px",
                        '& .MuiAlert-icon': {
                          color: "#8548D3",
                          marginRight: "10px",
                        },
                      }}
                    >
                      You’ve selected Medicare as your primary insurance plan. If you have a Medicare Advantage plan, please choose one of the other Medicare options instead.<br></br><br></br>

                      Medicare, including Medicare Advantage plans, cover medical nutrition therapy only for diabetes, kidney disease, or recent kidney transplants (within 36 months). A referral is required and must be uploaded in the final booking step.
                    </Alert>
                    <FormControlLabel className="my-1"
                      label={
                        <Typography variant="body1">
                          Do you have a secondary or supplemental plan?
                        </Typography>
                      }
                      labelPlacement="start"
                      control={<Switch checked={hasSecondary} onChange={handleSecondaryChange} sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: '#79ba39',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: '#79ba39',
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: '#a1de64',
                        },
                        '& .MuiSwitch-switchBase': {
                          color: '#a1de64',
                        },
                      }} />}
                    />
                    {hasSecondary && (
                      <>
                        <Select
                          options={insurenceProviderListWithType}
                          value={selectedSecondary}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          placeholder="Select your secondary/supplemental provider"
                          onChange={handleSecondaryProviderChange}
                          isSearchable
                          noOptionsMessage={() => "No choices"}
                          styles={defaultStyles}
                          className={` ${(error && hasSecondary && (!selectedSecondary || !selectedSecondary.value)) ? "error-select" : ""}`}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {error.message && (
              <p className={`err-insurance mx-auto text-danger error-msg mt-1`} style={{
                fontSize: '14px', fontWeight: "400", textAlign: 'center', position: "absolute", left: '50%',
                transform: 'translateX(-50%)'
              }}>
                {error.message}
              </p>
            )}

            {errorMessage && (
              <p className={`'err-insurance mx-auto text-danger error-msg mt-1`} style={{
                fontSize: '14px', fontWeight: "400", textAlign: 'center', left: '50%'
                
              }}>
                {errorMessage}
              </p>
            )}
          </div>

          <div className="mt-2">
            {!errorMessage && (
              <CommonButton
                isShowArrow
                onClick={handleSelectButton}>
                Continue
              </CommonButton>
            )}
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default InsuranceSelection;
